// Material-like table for cases where we don't use mat-table
table.default-table {
    border-spacing: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;

    > thead > tr > th, > tfoot > tr > th, > tfoot > tr > td, > tbody > tr > td, > tbody > tr > th {
        padding: 0 4px;
        border-bottom: 1px solid rgba(0, 0, 0, .12);
        text-align: left;

        &:first-of-type {
            padding-left: 24px;
        }

        &:last-of-type {
            padding-right: 24px;
        }
    }

    > thead > tr, > tfoot > tr {
        height: 56px;

        th {
            font-size: 12px;
            font-weight: 500;
            color: rgba(0, 0, 0, .54);
        }
    }

    > tbody > tr {
        height: 48px;

        th, td {
            color: rgba(0, 0, 0, .87);
            font-size: 14px;
        }
    }
}
