// This needs to be included after the variables are defined.
@import "default-table";
@import "../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

html, body {
    height: 100%;
    margin: 0;
    font-family: 'Roboto', Arial, sans-serif;
}

.default-form {
    mat-form-field {
        display: block;
    }
}

.full-width {
    width: 100%;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.mdc-fab.default-fab {
    position: fixed;
    bottom: 32px;
    right: 32px;
}

caption {
    caption-side: top;
}

a:link, a:visited {
    color: #3f51b5;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &.mat-menu-item, &.mat-list-item {
        color: inherit;
        text-decoration: inherit;
    }
}

.dropdown.show > .dropdown-menu {
    display: block;
}

[ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

table {
    & > caption {
        text-align: center;
    }

    & > thead th {
        white-space: nowrap;
    }
}

section > .caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #777;
    text-align: center;
}

.vacations {
    border-collapse: separate;
    border-spacing: 2px;
    border-color: gray;
    width: 100%;

    th {
        text-align: center;
    }

    .today {
        border-width: 2px;
    }

    .saving {
        cursor: progress;
    }

    td {
        width: 14%;
        padding: 2px;
    }
}

time {
    white-space: nowrap;
}

label {
    font-weight: normal;
}

.nowrap {
    white-space: nowrap;
}

// Material style guide says that buttons should be uppercased
.mcd-button, .mdc-stroked-button, .mdc-flat-button, .mdc-raised-button {
    text-transform: uppercase !important;
}

body {
    --mdc-text-button-label-text-transform: uppercase;
    --mdc-filled-button-label-text-transform: uppercase;
    --mdc-outlined-button-label-text-transform: uppercase;
    --mdc-protected-button-label-text-transform: uppercase;
}
